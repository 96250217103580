export interface GetAllUpsellsResponse {
    upsellConfigurations: SupplierUpsellType[];
    companyFormationsSuppliers: CompanyFormationsSupplierType[];
}

export enum UpsellPages {
    "/business-category" = "Business Category",
    "/" = "Company Name",
    "/company-name-success" = "Company Name Success",
    "/registered-office" = "Registered Office",
    "/celebrate-achievement" = "Celebrate Achievement",
    "/business-bank-account" = "Business Bank Account"
}

export interface SupplierUpsellType {
    pageUri: string;
    supplierId: string;
    upsells: UpsellType[];
}

export interface UpsellType {
    products: UpsellProductType[];
    upsellName: string;
    upsellId: string;
    description;
    order: number;
    currentSelectedProductId?: string;
    isSaving?: boolean;
    canDelete?: boolean;
    upsellContainsVirtualAddress: boolean;
    skipRegisteredOfficeStep: boolean;
    showSkipRegisteredOfficeStep: boolean;
    isCashPlusProduct: boolean;
    oneUpsellOnly: boolean;
    isOnlyImage: boolean;
    imageRelativePath?: string;
}

export interface UpsellProductType {
    id: string;
    name: string;
    description: string;
    price: number;
    priceFrequency: string;
    images: string[];
    moreInfo: string;
    productData;
}

export interface CompanyFormationsSupplierType {
    supplierId: string;
    supplierName: string;
}

export interface GetAllProductResponse {
    products: UpsellProductType[];
}

export interface UpsertUpsellCommand {
    upsellId: string;
    supplierId: string;
    productIds: string[];
    pageUri: string;
    order: number;
    upsellName: string;
    description;
}
