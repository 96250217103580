import { FormEvent, useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

import { createEditorState, getContentStateAsJson } from "@/Components/RichTextEditor/Utils";
import { useAPI } from "@/Apis/useAPI";
import { GetAllProductResponse,
    GetAllUpsellsResponse,
    SupplierUpsellType,
    UpsellPages,
    UpsellProductType,
    UpsellType,
    UpsertUpsellCommand } from "@/Apis/CompanyFormations";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { EncodedFile } from "@/Utils/base64EncodeHelper";

export const useManageCompanyFormationsUpsells = () => {
    const toastMessageContext = useToastMessageContext();
    const { loading, get, post, del } = useAPI({ handle500WithToastMessage: true });
    const [suppliers, setSuppliers] = useState<{ label: string, value: string }[]>([]);
    const [allUpsells, setAllUpsells] = useState({} as SupplierUpsellType[]);
    const [supplierUpsells, setSupplierUpsells] = useState([] as UpsellType[]);
    const [selectedSupplierId, setSelectedSupplierId] = useState("");
    const [selectedPage, setSelectedPage] = useState("");
    const [newUpsellName, setNewUpsellName] = useState("");
    const [allProducts, setAllProducts] = useState<UpsellProductType[]>([]);
    const [oneUpsellOnly, setOneUpsellOnly] = useState(false);
    const allPagesOptions = [{ label: "Select Page..", value: "" }, ...Object.keys(UpsellPages).map(key => ({ label: UpsellPages[key], value: key }))];
    const productOptions = ([{ label: "Select Product...", value: "" }, ...allProducts.map(x => ({ label: x.name, value: x.id }))]);

    useEffect(() => {
        get<GetAllProductResponse>("products/cache/all")
            .then(response => setAllProducts(response?.products));

        get<GetAllUpsellsResponse>("company-formations/admin/upsells")
            .then((response) => {
                setSuppliers([{ label: "Select Supplier...", value: "" }, ...response.companyFormationsSuppliers.map(x => ({ label: x.supplierName, value: x.supplierId }))]);
                setAllUpsells(response.upsellConfigurations.map(x => ({
                    supplierId: x.supplierId,
                    pageUri: x.pageUri,
                    upsells: x.upsells.map(upsell => ({
                        ...upsell,
                        description: createEditorState(upsell.description),
                        canDelete: !upsell.upsellContainsVirtualAddress,
                        showSkipRegisteredOfficeStep: x.pageUri !== "/registered-office" && x.pageUri !== "/business-category",
                    })),
                })));
            });
    }, []);

    const onSupplierChange = (selectedOption: { label, value }) => {
        setSelectedSupplierId(selectedOption.value);

        setSupplierUpsells([]);
        setSelectedPage(allPagesOptions[0].value);
    };

    const onPageChange = (selectedOption: { label, value }) => {
        let upsells: UpsellType[] | undefined = allUpsells.find(x => x.supplierId === selectedSupplierId && x.pageUri === selectedOption.value)?.upsells;
        if (!upsells) {
            const defaultUpsell: SupplierUpsellType = { pageUri: selectedOption.value, supplierId: selectedSupplierId, upsells: [] };
            setAllUpsells([...allUpsells, defaultUpsell]);
            upsells = defaultUpsell.upsells;
        }
        setSelectedPage(selectedOption.value);
        setSupplierUpsells([...upsells]);
        setOneUpsellOnly(upsells.some(x => x.oneUpsellOnly));
    };

    const onUpsellDescriptionChange = (upsellId: string) => (changedEditorState) => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;
            upsellToUpdate.description = changedEditorState;
            return newState;
        });
    };

    const onUpsellVirtualAddressFlagChanged = (upsellId: string) => (checked) => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;
            upsellToUpdate.upsellContainsVirtualAddress = checked;
            return newState;
        });
    };

    const onUpsellSkipOfficeStepChanged = (upsellId: string) => (checked) => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;
            upsellToUpdate.skipRegisteredOfficeStep = checked;
            return newState;
        });
    };

    const onUpsellCashplusProductStepChanged = (upsellId: string) => (checked) => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;
            upsellToUpdate.isCashPlusProduct = checked;
            return newState;
        });
    };

    const onAddUpsellProductChange = (upsellId: string) => (selectedOption: { label, value }) => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;
            upsellToUpdate.currentSelectedProductId = selectedOption.value;
            return newState;
        });
    };

    const onAddUpsellProduct = (upsellId: string) => () => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;

            if (!upsellToUpdate.currentSelectedProductId) {
                return newState;
            }

            upsellToUpdate.products.push({ ...allProducts.find(x => x.id === upsellToUpdate.currentSelectedProductId) } as UpsellProductType);
            upsellToUpdate.currentSelectedProductId = undefined;
            return newState;
        });
    };

    const onDeleteUpsellProduct = (upsellId: string, productId: string) => () => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;

            upsellToUpdate.products = upsellToUpdate.products.filter(x => x.id !== productId);

            return newState;
        });
    };

    const onNewUpsellNameChange = (e: FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        setNewUpsellName(() => newValue);
    };

    const onCreateUpsell = () => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const newUpsells = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType).upsells;
            const newOrder = (Math.max(...newUpsells.map(x => x.order)) + 1);
            newUpsells.push({ upsellId: uuid(),
                upsellName: newUpsellName,
                upsellContainsVirtualAddress: false,
                skipRegisteredOfficeStep: false,
                isCashPlusProduct: false,
                showSkipRegisteredOfficeStep: selectedPage !== "/registered-office" && selectedPage !== "/business-category",
                oneUpsellOnly,
                description: createEditorState(null),
                products: [],
                order: Number.isFinite(newOrder) ? newOrder : 0,
                isOnlyImage: false });
            onPageChange({ value: selectedPage, label: "" });
            setNewUpsellName("");
            return newState;
        });
    };

    const onDeleteUpsell = (upsellId: string) => async () => {
        await del(`company-formations/admin/upsells/${upsellId}`, {});
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const newSupplierUpsell = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType);
            newSupplierUpsell.upsells = newSupplierUpsell.upsells.filter(x => x.upsellId !== upsellId);
            onPageChange({ value: selectedPage, label: "" });
            return newState;
        });
    };

    const onUpsellNameChange = (upsellId: string) => (e: FormEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;
            upsellToUpdate.upsellName = newValue;
            return newState;
        });
    };

    const setUpsellIsSaving = (upsellId: string, isSaving: boolean) => {
        const newState = [...allUpsells];
        const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
            .upsells.find(x => x.upsellId === upsellId) as UpsellType;
        upsellToUpdate.isSaving = isSaving;
        if (!isSaving) {
            upsellToUpdate.canDelete = !upsellToUpdate.upsellContainsVirtualAddress;
        }
        setAllUpsells(newState);
    };

    const onUpsellSave = (upsell: UpsellType) => async () => {
        setUpsellIsSaving(upsell.upsellId, true);
        const response = await post("company-formations/admin/upsells", {
            ...upsell,
            description: getContentStateAsJson(upsell.description),
            pageUri: selectedPage,
            supplierId: selectedSupplierId,
            productIds: upsell.products.map(x => x.id),
        } as UpsertUpsellCommand);

        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsell.upsellId) as UpsellType;
            upsellToUpdate.imageRelativePath = response.imageRelativePath;
            return newState;
        });
        setUpsellIsSaving(upsell.upsellId, false);
    };

    const onOneUpsellOnlyChange = async (checked) => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === supplierUpsells[0].upsellId) as UpsellType;
            upsellToUpdate.oneUpsellOnly = checked;
            return newState;
        });
        await onUpsellSave({ ...supplierUpsells[0], oneUpsellOnly: checked })();
        setOneUpsellOnly(checked);
    };

    const copyProductCode = (upsellId: string, productId: string) => async () => {
        const upsellsQueryString = `upsells=${btoa(JSON.stringify({ upsellId, productId }))}`;
        await navigator.clipboard.writeText(upsellsQueryString);
        toastMessageContext.setSuccessMessage("Product details copied to clipboard", true);
    };

    const onIsOnlyImageRadioButtonChange = (upsellId: string, value: boolean) => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;
            upsellToUpdate.isOnlyImage = value;
            upsellToUpdate.description = createEditorState(null);
            upsellToUpdate.imageRelativePath = undefined;
            return newState;
        });
    };

    const onImageRelativeChange = (upsellId: string, files: EncodedFile[]) => {
        if (files?.length > 0) {
            setAllUpsells(currentState => {
                const newState = [...currentState];
                const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                    .upsells.find(x => x.upsellId === upsellId) as UpsellType;
                upsellToUpdate.imageRelativePath = files[0].data ?? "";
                return newState;
            });
        }
    };

    const onImageRelativeDelete = (upsellId: string) => {
        setAllUpsells(currentState => {
            const newState = [...currentState];
            const upsellToUpdate = (newState.find(x => x.pageUri === selectedPage && x.supplierId === selectedSupplierId) as SupplierUpsellType)
                .upsells.find(x => x.upsellId === upsellId) as UpsellType;
            upsellToUpdate.imageRelativePath = undefined;
            return newState;
        });
    };

    return {
        suppliers,
        selectedSupplierId,
        onSupplierChange,
        loading,
        allPagesOptions,
        onPageChange,
        selectedPage,
        onNewUpsellNameChange,
        newUpsellName,
        onCreateUpsell,
        supplierUpsells,
        onUpsellDescriptionChange,
        productOptions,
        onAddUpsellProductChange,
        onUpsellCashplusProductStepChanged,
        onAddUpsellProduct,
        onDeleteUpsellProduct,
        onUpsellNameChange,
        onDeleteUpsell,
        onUpsellSave,
        onUpsellVirtualAddressFlagChanged,
        onUpsellSkipOfficeStepChanged,
        oneUpsellOnly,
        onOneUpsellOnlyChange,
        copyProductCode,
        onIsOnlyImageRadioButtonChange,
        onImageRelativeChange,
        onImageRelativeDelete,
    };
};
