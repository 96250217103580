/* eslint-disable import/extensions */
import React from "react";
import Select from "react-select";
import classnames from "classnames";
import { Button } from "reactstrap";

import { useManageCompanyFormationsUpsells } from "./useManageCompanyFormationsUpsells";
import styles from "./styles.module.scss";

import dropZoneStyles from "@/Components/Dropzones/DropZoneArea/styles.module.scss";
import NoResultsFound from "@/Components/NoResultsFound";
import Spinner from "@/Components/Spinner";
import Checkbox from "@/Components/Checkbox";
import { RichTextEditor } from "@/Components/RichTextEditor";
import Textbox from "@/Components/Textbox";
import { getFrequencyAlternate, toCurrency } from "@/Utils/formatStringHelper";
import RadioButton from "@/Components/RadioButton";
import { DropZoneArea } from "@/Components/Dropzones/DropZoneArea";
import { acceptedFileTypes } from "@/Utils/constants";

const ManageCompanyFormationsUpsells = () => {
    document.title = "BSC - Company Formations Upsells";
    const {
        suppliers,
        selectedSupplierId,
        onSupplierChange,
        loading,
        allPagesOptions,
        onPageChange,
        selectedPage,
        onNewUpsellNameChange,
        newUpsellName,
        onCreateUpsell,
        supplierUpsells,
        onUpsellDescriptionChange,
        productOptions,
        onAddUpsellProductChange,
        onUpsellCashplusProductStepChanged,
        onAddUpsellProduct,
        onDeleteUpsellProduct,
        onUpsellNameChange,
        onDeleteUpsell,
        onUpsellSave,
        onUpsellVirtualAddressFlagChanged,
        onUpsellSkipOfficeStepChanged,
        onOneUpsellOnlyChange,
        oneUpsellOnly,
        copyProductCode,
        onIsOnlyImageRadioButtonChange,
        onImageRelativeChange,
        onImageRelativeDelete,
    } = useManageCompanyFormationsUpsells();

    return (
        <div className="pb-5">
            <h4 className="font-weight-bold mt-4 ml-3">Manage Company Formation Upsells</h4>
            <div className="d-flex flex-wrap mt-4 ml-3">
                <div className={styles.inputWidth}>
                    <Select
                        value={suppliers?.find(x => x.value === selectedSupplierId)}
                        onChange={onSupplierChange}
                        options={suppliers}
                        isLoading={loading}
                    />
                </div>
                <div className={classnames(styles.arrow, "text-center")}>
                    <i className="fas fa-arrow-right h4" />
                </div>
                <div className={styles.inputWidth}>
                    <Select
                        value={allPagesOptions.find(x => x.value === selectedPage)}
                        onChange={onPageChange}
                        options={allPagesOptions}
                        isLoading={loading}
                        placeholder={allPagesOptions[0].label}
                    />
                </div>
            </div>
            <div className="mt-4 ml-3">
                <div className="d-flex">
                    <Textbox placeholder="Upsell Name..." onChange={onNewUpsellNameChange} value={newUpsellName} className={classnames(styles.inputWidth, "mr-2")} />
                    <Button color="primary" className={styles.button} onClick={onCreateUpsell} disabled={!newUpsellName || !selectedSupplierId || !selectedPage}>Create</Button>
                    <div className={classnames(styles.arrow, "text-center font-weight-bold h4")}>｜</div>
                    {supplierUpsells.length >= 2 && supplierUpsells[0].isSaving
                        ? <Spinner /> : (
                            <Checkbox
                                id="oneUpsellOnly"
                                checked={oneUpsellOnly && supplierUpsells.length >= 2}
                                label="User Can Only Select One Upsell On This Page"
                                className="my-2"
                                onChange={onOneUpsellOnlyChange}
                                disabled={supplierUpsells.length <= 1}
                            />
                        )}
                </div>
                <hr />
            </div>
            {loading && !supplierUpsells.find(x => x.isSaving) && (<Spinner className="m-4" />)}
            <div className="d-flex flex-wrap">
                {(!loading || supplierUpsells.find(x => x.isSaving)) && (
                    <>
                        {supplierUpsells.length === 0 && (<NoResultsFound />)}
                        {supplierUpsells?.map(upsell => (
                            <div className={classnames(styles.upsellContainer, "rounded box-shadow m-3 p-3 bg-white")} key={upsell.upsellId}>
                                <Button
                                    color="primary"
                                    className={classnames(styles.saveButton, "w-100")}
                                    onClick={onUpsellSave(upsell)}
                                    disabled={upsell.products.length === 0 || !upsell.upsellName || upsell.isSaving || (upsell.isOnlyImage && !upsell.imageRelativePath)}
                                >
                                    {!upsell.isSaving && (<>Save</>)}
                                    {upsell.isSaving && (<i className="fas fa-circle-notch fa-spin" />)}
                                </Button>
                                <Button
                                    className={classnames(!upsell.canDelete && "invisible", styles.deleteButton,
                                        "p-0 m-0 border-0 bg-transparent text-danger mr-4")}
                                    onClick={onDeleteUpsell(upsell.upsellId)}
                                >
                                    <i className="fas fa-times-circle" />
                                </Button>
                                <div className={styles.upsellRichTextContainer}>
                                    <Textbox
                                        placeholder="Upsell Name..."
                                        onChange={onUpsellNameChange(upsell.upsellId)}
                                        value={upsell.upsellName}
                                        className={classnames(styles.upsellNameTextbox, "flex-grow-1 mr-2")}
                                    />
                                    <div className="tw-flex tw-space-x-3 tw-my-3">
                                        <RadioButton
                                            id={`rad-only-text-${upsell.upsellId}`}
                                            label="Add Text only"
                                            checked={!upsell.isOnlyImage}
                                            onChange={() => onIsOnlyImageRadioButtonChange(upsell.upsellId, false)}
                                            name={`rad-only-text-${upsell.upsellId}`}
                                        />
                                        <RadioButton
                                            id={`rad-only-img-${upsell.upsellId}`}
                                            label="Add Image only"
                                            checked={upsell.isOnlyImage}
                                            onChange={() => onIsOnlyImageRadioButtonChange(upsell.upsellId, true)}
                                            name={`rad-only-img-${upsell.upsellId}`}
                                        />
                                    </div>
                                    {upsell.isOnlyImage ? (
                                        <>
                                            {upsell.imageRelativePath ? (
                                                <div className="tw-flex tw-flex-row tw-justify-between tw-items-end tw-space-x-2">
                                                    <picture>
                                                        <source srcSet={upsell.imageRelativePath.startsWith("data")
                                                            ? upsell.imageRelativePath : `${window.cdnUrl}${upsell.imageRelativePath}`}
                                                        />
                                                        <img
                                                            src={upsell.imageRelativePath.startsWith("data")
                                                                ? upsell.imageRelativePath : `${window.cdnUrl}${upsell.imageRelativePath}`}
                                                            alt="upsell img"
                                                            className="tw-flex tw-relative tw-flex-col tw-max-h-72 tw-w-full tw-object-contain"
                                                        />
                                                    </picture>
                                                    <div className="tw-flex tw-flex-row tw-items-center">
                                                        <Button
                                                            color="danger"
                                                            onClick={() => onImageRelativeDelete(upsell.upsellId)}
                                                        >
                                                            <i className="fas fa-trash-alt" />
                                                        </Button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <DropZoneArea
                                                    onAdd={(files) => onImageRelativeChange(upsell.upsellId, files)}
                                                    accept={acceptedFileTypes.upsellImages}
                                                    maxSize={2000000}
                                                    className={classnames(dropZoneStyles.dropzone, "tw-mt-4")}
                                                >
                                                    <div className="tw-py-6 tw-text-center">
                                                        <div className="tw-font-semibold">Drag and drop image to upload here</div>
                                                        <div className="tw-font-semibold">Or Tap to select image to upload</div>
                                                        <span className="tw-text-xs tw-text-gray-500 tw-italic">Max. File size 2MB, File types: .jpg, .jpeg, .png, .webp</span>
                                                    </div>
                                                </DropZoneArea>
                                            )}
                                        </>
                                    ) : (
                                        <RichTextEditor
                                            placeholder="Upsell Description..."
                                            onChange={onUpsellDescriptionChange(upsell.upsellId)}
                                            editorState={upsell.description}
                                        />
                                    )}

                                    <Checkbox
                                        key={`${upsell.upsellId}_virtual`}
                                        id={`${upsell.upsellId}_virtual`}
                                        checked={upsell.upsellContainsVirtualAddress}
                                        label="Contains Virtual Address"
                                        className="my-2"
                                        onChange={onUpsellVirtualAddressFlagChanged(upsell.upsellId)}
                                    />
                                    <Checkbox
                                        key={`${upsell.upsellId}_office`}
                                        id={`${upsell.upsellId}_office`}
                                        checked={upsell.skipRegisteredOfficeStep}
                                        disabled={!upsell.showSkipRegisteredOfficeStep}
                                        label="Skip Registered Office Page"
                                        className="my-2"
                                        onChange={onUpsellSkipOfficeStepChanged(upsell.upsellId)}
                                    />
                                    {selectedPage.includes("business-bank-account") && (
                                        <Checkbox
                                            key={`${upsell.upsellId}_cashplus`}
                                            id={`${upsell.upsellId}_cashplus`}
                                            checked={upsell.isCashPlusProduct}
                                            label="This is a Cashplus Product"
                                            className="my-2"
                                            onChange={onUpsellCashplusProductStepChanged(upsell.upsellId)}
                                        />
                                    )}
                                </div>
                                <hr />
                                <div className="d-flex">
                                    <Select
                                        className="flex-grow-1 mr-2"
                                        options={productOptions}
                                        value={productOptions.find(x => x.value === (upsell.currentSelectedProductId ?? ""))}
                                        isLoading={loading}
                                        onChange={onAddUpsellProductChange(upsell.upsellId)}
                                    />
                                    <Button
                                        color="primary"
                                        className={styles.button}
                                        onClick={onAddUpsellProduct(upsell.upsellId)}
                                        disabled={!upsell.currentSelectedProductId}
                                    >
                                        Add
                                    </Button>
                                </div>
                                {upsell?.products?.map(product => (
                                    <div className="tw-grid tw-grid-cols-7 tw-gap-3 tw-pt-4" key={product.id}>
                                        <div className="tw-col-span-2">
                                            <div className="tw-break-words">
                                                <h5 className="tw-font-bold">
                                                    £{toCurrency(product.price)}
                                                    <span className="tw-text-gray-400 tw-text-xs"> {getFrequencyAlternate(product.priceFrequency ?? "")}</span>
                                                </h5>
                                            </div>
                                        </div>
                                        <div className="tw-col-span-4">
                                            <div className="tw-line-clamp-1 tw-break-words">
                                                {product.name}
                                            </div>
                                        </div>
                                        <div className="tw-col-span-1">
                                            <Button
                                                className="p-0 m-0 border-0 bg-transparent text-danger mr-2 h6"
                                                onClick={onDeleteUpsellProduct(upsell.upsellId, product.id)}
                                            >
                                                <i className="fas fa-trash-alt" />
                                            </Button>
                                            <Button className="p-0 m-0 border-0 bg-transparent text-dark mr-2 h6">
                                                <i className="fas fa-info-circle" />
                                            </Button>
                                            <Button className="p-0 m-0 border-0 bg-transparent text-dark h6" onClick={copyProductCode(upsell.upsellId, product.id)}>
                                                <i className="far fa-copy" />
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                                <hr />
                            </div>))}
                    </>)}
            </div>
        </div>
    );
};

export { ManageCompanyFormationsUpsells };
