import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Label } from "reactstrap";
import moment from "moment";

import Textbox from "@/Components/Textbox";
import { isEmptyOrSpaces,
    isValidAddressLine,
    isValidBusinessNameOnCard,
    isValidCompanyName,
    isValidCompanyRegistrationNumber,
    isValidEmailAddress,
    isValidFirstOrLastName,
    isValidMobileNumber,
    isValidPostCode,
    isValidPremise,
    isValidStartsWithAMobileNumber,
    isValidStartsWithASingleCharacter,
    isValidStartsWithAWorkNumber,
    isValidTownOrCity,
    isValidWorkNumber } from "@/Utils/stringHelper";
import Spinner from "@/Components/Spinner";
import { useAPI } from "@/Apis/useAPI";
import { useToastMessageContext } from "@/Context/ToastMessageContext";
import { FloatLabelAddressLookUp } from "@/Components/FloatLabelAddressLookup";
import DateTextbox from "@/Components/DateTextbox";
import { AddressType } from "@/Apis/Profile";
import { BankAccount, BankAccountOrder, BankAccountStatus, FieldType, titles } from "@/Apis/BankAccount";
import { isInteger } from "@/Utils/numberHelper";
import { Urls } from "@/Apis/urls";
import { ConfirmCancelModal } from "@/Components/ConfirmCancelModal";
import { sicRanges, sicSections } from "@/Apis/SicSection";
import { features } from "@/Utils/features";

interface Props {
    orderId: string,
    loading: boolean;
}

export const BankAccountTab = ({ orderId, loading }: Props) => {
    const { get, put } = useAPI({ handle500WithToastMessage: true });
    const { setSuccessMessage, setPopupErrorMessage } = useToastMessageContext();

    const [formData, setFormData] = useState<BankAccount>({} as BankAccount);
    const [bankAccountOrder, setBankAccountOrder] = useState<BankAccountOrder>();
    const [errorMessage, setErrorMessage] = useState<string>();

    const [processing, setProcessing] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const [submitConfirmModalOpen, setSubmitConfirmModalOpen] = useState(false);

    const getBankAccount = () => {
        setProcessing(true);
        get<BankAccountOrder>(Urls.bankAccounts.get(orderId))
            .then((response) => {
                setBankAccountOrder(response);
                const bankAccount = {} as BankAccount;
                response?.bankAccountOrderFields?.forEach(x => {
                    if (x.attributeName === "title" && !isEmptyOrSpaces(x.orderFieldAnswer)) {
                        bankAccount[x.attributeName] = x.orderFieldAnswer.replace(".", "");
                        return;
                    }
                    if (x.orderFieldType === FieldType.AddressLookup && !isEmptyOrSpaces(x.orderFieldAnswer)) {
                        bankAccount[x.attributeName] = JSON.parse(x.orderFieldAnswer);
                        return;
                    }
                    if (x.attributeName === "sicCode" && !isEmptyOrSpaces(x.orderFieldAnswer)) {
                        const sicCode = parseInt(x.orderFieldAnswer, 10);
                        const sicRange = sicRanges?.find(x => x.value !== 1 && x.startRange <= sicCode && sicCode <= x.endRange);
                        if (sicRange) {
                            const sicSection = sicSections?.find(x => x.value === sicRange.value);
                            if (sicSection) {
                                bankAccount[x.attributeName] = sicCode;
                                bankAccount.industryId = sicSection.industryId;
                            }
                        }
                        return;
                    }
                    bankAccount[x.attributeName] = x.orderFieldAnswer;
                });
                setFormData(bankAccount);
            })
            .catch((err) => setErrorMessage(err))
            .finally(() => {
                setProcessing(false);
            });
    };

    useEffect(() => {
        getBankAccount();
    }, []);

    const onTextInput = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e?.target?.value ?? "";
        switch (field) {
            case "companyRegistrationNumber":
                if (isValidCompanyRegistrationNumber(value)) {
                    setFormData({
                        ...formData,
                        [field]: value,
                    } as BankAccount);
                }
                break;
            case "companyName":
                if (isValidCompanyName(value)) {
                    setFormData({
                        ...formData,
                        [field]: value,
                    } as BankAccount);
                }
                break;
            case "numberOfEmployees":
                if (isInteger(value) || isEmptyOrSpaces(value)) {
                    setFormData({
                        ...formData,
                        [field]: isEmptyOrSpaces(value) ? undefined : parseInt(value, 10),
                    } as BankAccount);
                }
                break;
            case "businessNameOnCard":
                if (isValidBusinessNameOnCard(value) || isValidStartsWithASingleCharacter(value)) {
                    setFormData({
                        ...formData,
                        [field]: value,
                    } as BankAccount);
                }
                break;
            case "firstName":
            case "lastName":
                if (isValidFirstOrLastName(value)) {
                    setFormData({
                        ...formData,
                        [field]: value,
                    } as BankAccount);
                }
                break;
            case "mobileNumber":
                if (value === "0" || isValidMobileNumber(value) || isValidStartsWithAMobileNumber(value)) {
                    setFormData({
                        ...formData,
                        [field]: value,
                    } as BankAccount);
                }
                break;
            case "workNumber":
                if (isValidWorkNumber(value) || isValidStartsWithAWorkNumber(value)) {
                    setFormData({
                        ...formData,
                        [field]: value,
                    } as BankAccount);
                }
                break;
            default:
                setFormData({
                    ...formData,
                    [field]: value,
                } as BankAccount);
                break;
        }
    };

    const onAddressChange = (field: string) => (value: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [field]: value,
        } as BankAccount);
    };

    const onControlInput = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value,
        } as BankAccount);
    };

    const onDateInput = (field: string, value: any) => {
        setFormData({
            ...formData,
            [field]: value ? moment(value).format("YYYY-MM-DD 00:00:00") : null,
        } as BankAccount);
    };

    const isInvalidAddress = (address: AddressType | undefined) => isEmptyOrSpaces(address?.city) || !isValidTownOrCity(address?.city)
        || !isValidPostCode(address?.postCode)
        || !isValidAddressLine(address?.addressLine1) || (address?.addressLine1?.length ?? 0) > 50
        || isEmptyOrSpaces(address?.premise) || !isValidPremise(address?.premise) || (address?.premise?.length ?? 0) > 50;

    const isInvalidForm = () => isEmptyOrSpaces(formData?.companyRegistrationNumber) || !isValidCompanyRegistrationNumber(formData?.companyRegistrationNumber)
        || isInvalidAddress(formData?.companyRegisteredOfficeAddress)
        || isEmptyOrSpaces(formData?.companyName) || !isValidCompanyName(formData?.companyName)
        || !isInteger(formData?.numberOfEmployees)
        || isEmptyOrSpaces(formData?.businessNameOnCard) || !isValidBusinessNameOnCard(formData?.businessNameOnCard)
        || isEmptyOrSpaces(formData?.title)
        || isEmptyOrSpaces(formData?.firstName) || !isValidFirstOrLastName(formData?.firstName)
        || isEmptyOrSpaces(formData?.lastName) || !isValidFirstOrLastName(formData?.lastName)
        || isEmptyOrSpaces(formData?.dateOfBirth)
        || isEmptyOrSpaces(formData?.emailAddress) || !isValidEmailAddress(formData?.emailAddress)
        || isEmptyOrSpaces(formData?.mobileNumber) || !isValidMobileNumber(formData?.mobileNumber)
        || isEmptyOrSpaces(formData?.workNumber) || !isValidWorkNumber(formData?.workNumber)
        || isInvalidAddress(formData?.homeAddress);

    const getSubmitButtonText = () => {
        if (features.isEnabled(features.cashPlusApi)) {
            return "Submit";
        }
        return "Save";
    };

    const onSave = () => {
        if (isInvalidForm()) {
            setShowValidation(true);
            setSubmitConfirmModalOpen(false);
            return;
        }

        setProcessing(true);
        const orderFields = bankAccountOrder?.bankAccountOrderFields?.map(x => {
            const newValue = formData[x.attributeName];
            if (x.attributeName === "title") {
                return { ...x, orderFieldAnswer: `${newValue}.` };
            }
            if (x.orderFieldType === FieldType.AddressLookup) {
                return { ...x, orderFieldAnswer: JSON.stringify(newValue) };
            }
            if (x.orderFieldType === FieldType.Date) {
                return { ...x, orderFieldAnswer: moment(newValue).format("YYYY-MM-DD 00:00:00") };
            }
            return { ...x, orderFieldAnswer: newValue.toString() };
        });

        put<BankAccountStatus>(Urls.bankAccounts.upsertLimitedCompany(orderId), {
            bankAccount: formData,
            bankAccountOrderFields: orderFields,
        })
            .then((response) => {
                if (features.isEnabled(features.cashPlusApi)) { // Access the response information
                    if (response.errorCode) {
                        setPopupErrorMessage(response.message, true);
                    } else {
                        setSuccessMessage(`Bank account application has been submitted. Message: ${response.message}`, true);
                    }
                } else { // No API response as we simply updated the fields
                    setSuccessMessage("Order answers updated", true);
                }
            })
            .catch(error => {
                setPopupErrorMessage(error, true);
            })
            .finally(() => {
                setProcessing(false);
                setSubmitConfirmModalOpen(false);
            });
    };

    if (errorMessage) {
        return <div className="tw-text-red-500">{errorMessage}</div>;
    }

    return (
        <>
            <div>
                <Textbox
                    label="Company Registration Number *"
                    maxLength={8}
                    className="!tw-mb-2"
                    onChange={onTextInput("companyRegistrationNumber")}
                    value={formData?.companyRegistrationNumber}
                    disabled={processing}
                    error={(isEmptyOrSpaces(formData?.companyRegistrationNumber) || !isValidCompanyRegistrationNumber(formData?.companyRegistrationNumber))
                            && showValidation ? "Please enter a valid company registration number" : ""}
                />
                <FloatLabelAddressLookUp
                    customHeading={<div className="tw-text-sm tw-pb-2">Company Registered Office Address *</div>}
                    address={formData?.companyRegisteredOfficeAddress ?? { premise: "", addressLine2: "", addressLine1: "", postCode: "", city: "" } as AddressType}
                    onSave={onAddressChange("companyRegisteredOfficeAddress")}
                />
                {isInvalidAddress(formData?.companyRegisteredOfficeAddress) && showValidation && (
                    <div className="!tw-font-bold !tw-text-[#bb5360] !tw-text-[0.875em] !tw-block">
                        Please enter a valid premise, address line 1, city, and post code
                    </div>
                )}
                <Textbox
                    label="Company Name *"
                    maxLength={50}
                    className="!tw-mb-2"
                    onChange={onTextInput("companyName")}
                    value={formData?.companyName}
                    disabled={processing}
                    placeholder="From 3 to 50 characters"
                    error={(isEmptyOrSpaces(formData?.companyName) || !isValidCompanyName(formData?.companyName))
                        && showValidation ? "Please enter a valid company name" : ""}
                />
                <Textbox
                    label="Number of Employees *"
                    maxLength={11}
                    className="!tw-mb-2"
                    onChange={onTextInput("numberOfEmployees")}
                    value={formData?.numberOfEmployees?.toString()}
                    disabled={processing}
                    placeholder="Greater than or equal to 0"
                    error={!isInteger(formData?.numberOfEmployees)
                        && showValidation ? "Please enter a valid number of employees" : ""}
                />
                <Textbox
                    label="Business Name on Card *"
                    maxLength={21}
                    className="!tw-mb-2"
                    onChange={onTextInput("businessNameOnCard")}
                    value={formData?.businessNameOnCard}
                    disabled={processing}
                    placeholder="Start with an uppercase, a lowercase letter, or a digit, followed by one or more characters."
                    error={(isEmptyOrSpaces(formData?.businessNameOnCard) || !isValidBusinessNameOnCard(formData?.businessNameOnCard))
                        && showValidation ? "Please enter a valid business name on card" : ""}
                />
                <div className="tw-mb-2">
                    <Label>Title *</Label>
                    <Select
                        value={titles?.map((x) => ({ label: x, value: x }))?.find(x => x.value === formData?.title)}
                        onChange={e => onControlInput("title", e?.value)}
                        options={titles?.map((x) => ({ label: x, value: x }))}
                        isDisabled={processing}
                        isClearable
                    />
                    {isEmptyOrSpaces(formData?.title) && showValidation && (
                        <div className="!tw-font-bold !tw-text-[#bb5360] !tw-text-[0.875em] !tw-block">
                            Please enter a valid title
                        </div>
                    )}
                </div>
                <Textbox
                    label="First Name *"
                    maxLength={50}
                    className="!tw-mb-2"
                    onChange={onTextInput("firstName")}
                    value={formData?.firstName}
                    disabled={processing}
                    placeholder="Only letters, hyphens and spaces (2 - 50 characters)"
                    error={(isEmptyOrSpaces(formData?.firstName) || !isValidFirstOrLastName(formData?.firstName))
                        && showValidation ? "Please enter a valid first name" : ""}
                />
                <Textbox
                    label="Last Name *"
                    maxLength={50}
                    className="!tw-mb-2"
                    onChange={onTextInput("lastName")}
                    value={formData?.lastName}
                    disabled={processing}
                    placeholder="Only letters, hyphens and spaces (2 - 50 characters)"
                    error={(isEmptyOrSpaces(formData?.lastName) || !isValidFirstOrLastName(formData?.lastName))
                        && showValidation ? "Please enter a valid last name" : ""}
                />
                <DateTextbox
                    label="Date of Birth *"
                    changeDate={e => onDateInput("dateOfBirth", e)}
                    currentDate={!formData?.dateOfBirth ? "" : formData?.dateOfBirth}
                    disabled={processing}
                    min={new Date(1900, 0, 1)}
                    max={moment.utc().add(-18, "year").toDate()}
                    error={isEmptyOrSpaces(formData?.dateOfBirth)
                        && showValidation ? "Please enter a valid date of birth" : ""}
                />
                <Textbox
                    label="Email Address *"
                    maxLength={100}
                    className="!tw-mb-2"
                    onChange={onTextInput("emailAddress")}
                    value={formData?.emailAddress}
                    placeholder="value@value.value"
                    disabled={processing}
                    error={(isEmptyOrSpaces(formData?.emailAddress) || !isValidEmailAddress(formData?.emailAddress))
                        && showValidation ? "Please enter a valid email address" : ""}
                />
                <Textbox
                    label="Mobile Number *"
                    maxLength={11}
                    className="!tw-mb-2"
                    onChange={onTextInput("mobileNumber")}
                    value={formData?.mobileNumber}
                    disabled={processing}
                    placeholder='Start with "07" and be followed by exactly 9 digits'
                    error={(isEmptyOrSpaces(formData?.mobileNumber) || !isValidMobileNumber(formData?.mobileNumber))
                        && showValidation ? "Please enter a valid mobile number" : ""}
                />
                <Textbox
                    label="Work Number *"
                    maxLength={11}
                    className="!tw-mb-2"
                    onChange={onTextInput("workNumber")}
                    value={formData?.workNumber}
                    disabled={processing}
                    placeholder='Start with "0" and be followed by exactly 10 digits'
                    error={(isEmptyOrSpaces(formData?.workNumber) || !isValidWorkNumber(formData?.workNumber))
                        && showValidation ? "Please enter a valid work number" : ""}
                />
                <FloatLabelAddressLookUp
                    customHeading={<div className="tw-text-sm tw-pb-2">Home Address *</div>}
                    address={formData?.homeAddress ?? { premise: "", addressLine2: "", addressLine1: "", postCode: "", city: "" } as AddressType}
                    onSave={onAddressChange("homeAddress")}
                />
                {isInvalidAddress(formData?.homeAddress) && showValidation && (
                    <div className="!tw-font-bold !tw-text-[#bb5360] !tw-text-[0.875em] !tw-block">
                        Please enter a valid premise, address line 1, city, and post code
                    </div>
                )}
            </div>
            <div className="tw-flex tw-justify-center tw-space-x-4 tw-mt-3">
                <Button
                    color="primary"
                    onClick={features.isEnabled(features.cashPlusApi) ? () => setSubmitConfirmModalOpen(true) : onSave}
                    disabled={loading || processing}
                    data-testid="btn-save"
                    size="lg"
                >
                    {processing ? (<Spinner className="mx-auto" />) : getSubmitButtonText()}
                </Button>
            </div>
            <ConfirmCancelModal
                isOpen={submitConfirmModalOpen}
                text="Are you sure you want to submit the business bank account?"
                onConfirm={onSave}
                onClose={() => setSubmitConfirmModalOpen(false)}
                loading={processing}
            />
        </>
    );
};
