class features {
    static get customisedProducts() {
        return "customisedProducts";
    }

    static get recaptcha() {
        return "reCaptcha";
    }

    static get signalR() {
        return "signalR";
    }

    static get userManagement() {
        return "userManagement";
    }

    static get FlexPlan() {
        return "flexPlan";
    }

    static get googleSignIn() {
        return "googleSignIn";
    }

    static get cashPlusApi() {
        return "cashPlusApi";
    }

    static isEnabled(feature) {
        if (window.bscFeatures && window.bscFeatures[feature]) {
            return window.bscFeatures[feature];
        }
        return false;
    }
}

export { features };
